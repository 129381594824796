import React from 'react';

import ReactDOM from 'react-dom';

// import registerServiceWorker from './registerServiceWorker';

import { unregister } from './registerServiceWorker';



import { HashRouter } from 'react-router-dom';

import './assets/base.css';

import Main from './DemoPages/Main';

import configureStore from './config/configureStore';

import { Provider } from 'react-redux';
import Signin  from './Auth/Signin';



const store = configureStore();

const rootElement = document.getElementById('root');


const renderApp = Component => {

  ReactDOM.render(

    <Provider store={store}>

      <HashRouter>

        <Component />

      </HashRouter>

    </Provider>,

    rootElement

  );

};

if(!sessionStorage.getItem('key')) {
  console.log("session",sessionStorage.getItem('key'));
  
  renderApp(Signin);
  if (module.hot) {

    module.hot.accept('./Auth/Signin', () => {
  
      const NextApp = require('./Auth/Signin');
  
      renderApp(NextApp);
  
    });
  
  }
  
  unregister();
}
else{
  renderApp(Main);
  if (module.hot) {

    module.hot.accept('./DemoPages/Main', () => {
  
      const NextApp = require('./DemoPages/Main');
  
      renderApp(NextApp);
  
    });
  
  }
  
  unregister();
}








// registerServiceWorker();