import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './DoStyle.css';

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
import './Signin.css';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { unregister } from '../registerServiceWorker';
import Main from '../DemoPages/Main';
import configureStore from '../config/configureStore';
import { Provider } from 'react-redux';
import axios from "axios";


const store = configureStore();
const rootElement = document.getElementById('root');




const Signin = () => {

    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [products, setProducts] = React.useState([]);

    useEffect(() => {
       
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({
        initialValues: {
            name: '',
            password: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'User name is required.';
            }

            if (!data.password) {
                errors.password = 'Password is required.';
            }

            return errors;
        },
        onSubmit: async (data) => {

            if((data.name =="admin" || data.name=="Admin") && data.password=="IndiraClinic@296")
            {
                sessionStorage.clear();
                sessionStorage.setItem('key',0);
                sessionStorage.setItem('Name', data.name);
                Signinattempt();
                setShowMessage(true);
                formik.resetForm();
            }
            else {

                try{
                    const response = await axios.post(process.env.REACT_APP_API_URL+"/api/Token", {
                        'usr_loginid': data.name,'usr_pwd': data.password       
                    });  
                    if (response.status=200) {
                        sessionStorage.clear();
                       
                        sessionStorage.setItem('key', response.data[0]);
                        sessionStorage.setItem('Name', response.data[1]);
                        Signinattempt(); 
                      }               
                        
                }
                catch (error) { 
                    alert("Invalid Username or Password!!!");
                    console.log("LoginAttempt",error);
                    formik.resetForm();
                }
               
            }
            
        }
    });

    const Signinattempt = () => {
        const renderApp = Component => {
            ReactDOM.render(
              <Provider store={store}>
                <HashRouter>
                  <Component />
                </HashRouter>
              </Provider>,
              rootElement
            );
          };
          
          renderApp(Main);
          
          if (module.hot) {
            module.hot.accept('../DemoPages/Main', () => {
              const NextApp = require('../DemoPages/Main').default;
              renderApp(NextApp);
            });
          }
          unregister();
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div className="form-demo">
            <div className="flex justify-content-center">
                <div className="card">
              
                    <div>
                    <Avatar icon="pi pi-user" size="large" shape="circle" className="p-fluid Avatar" style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} />
                    </div>
                    <h5 className="text-center text_heading">Sign in</h5>        
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field">
                            <span className="p-float-label">
                                <InputText id="name" autoComplete='off' name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>User name*</label>
                            </span>
                            {getFormErrorMessage('name')}
                        </div>
                      
                        <div className="field">
                            <span className="p-float-label">
                                <Password id="password" autoComplete='off' name="password" feedback={false} value={formik.values.password} onChange={formik.handleChange} toggleMask
                                    className={classNames({ 'p-invalid': isFormFieldValid('password') })} />
                                <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') })}>Password*</label>
                            </span>
                            {getFormErrorMessage('password')}
                        </div>
                       
                        <Button type="submit" label="Login to Dashboard" className="mt-2" />
                        <p style={{marginLeft :'15rem', marginTop:'1rem'}}>Copyright ©Multimedia 2022.</p>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default Signin             